<template>
    <nav class="navbar">
        <div class="container">
            <a class="navbar-brand" href="http://zamok-more.ru">
                <img :src="logoPath()" alt="logo">
            </a>
        </div>
    </nav>
    <div class="container main">
        <div class="alert alert-warning alert-dismissible fade show" role="alert">
            Для расчета стоимости бронирования необходимо выбрать <code>даты заезда</code>, 
            ввести кол-во <code>взрослых</code> и <code>детей</code>. После ввода всех данных появится кнопка <code>РАСЧЕТ</code>. 
            После нажатия на кнопку вы увидите список подходящих <code>категорий</code> с указанием <code>мест</code> в номере и 
            <code>ценой</code>. Для того чтобы посмотреть детали бронирования, щелкните на значении <code>цены</code> в нужной <code>категории</code>
            — появится окно с подробностями расчета.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>

        <!-- <div class="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>Внимание!</strong> Скидка 10% на размещение в МАЕ! Стоимость в калькуляторе рассчитывается без скидки.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div> -->

        <h3 class="title mt-2">РАСЧЕТ СТОИМОСТИ БРОНИРОВАНИЯ</h3>
        <div class="row mt-2">
            <div class="col">
                <label class="form-label" v-text="dateLabel()"></label>
                <strong>
                    <Datepicker
                        v-model="date"
                        locale="ru"
                        range
                        multiCalendars
                        :enableTimePicker="false"
                        autoApply
                        :minDate="minDate"
                        :maxDate="maxDate"
                        preventMinMaxNavigation
                        hideOffsetDates
                        teleport-center
                        :monthChangeOnScroll="false"
                        monthNameFormat="long"
                        :yearRange="[2023, 2025]"
                        :format="format"
                    >
                        <template #calendar-header="{ index, day }">
                            <div :class="index === 5 || index === 6 ? 'weekend' : ''">
                                {{ day }}
                            </div>
                        </template>
                    </Datepicker>
                </strong>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col">
                <label for="adults" class="form-label">Взрослые <br><small>от 13 лет</small></label>
                <input id="adults" v-model="adults" type="number" min="0" max="10" class="form-control">
            </div>
            <div class="col">
                <label for="children" class="form-label">Дети <br><small>от 4-12 лет</small></label>
                <input id="children" v-model="children" type="number" min="0" max="10" class="form-control">
            </div>
            <div class="col">
                <label for="infants" class="form-label">Дети <br><small>до 3 лет</small></label>
                <input id="infants" v-model="infants" type="number" min="0" max="10" class="form-control">
            </div>
        </div>
        <div class="d-grid gap-2 mt-4 mb-3">
            <button v-if="showButtonCalc" @click="calcAllCategories()" type="button" class="btn btn-calc">РАСЧЕТ</button>
        </div>
        
        <table class="table" v-if="showResult">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Категория</th>
                    <th scope="col">Мест</th>
                    <th scope="col" class="right">Цена &#8381;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(category, index) in result" :key="index">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ category.name }}</td>
                    <td>{{ category.beds }}</td>
                    <td class="right">
                        <span class="info" @click="showModal(index)">{{ numRub(category.cost) }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal" ref="infoModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title"><div class="float-start pe-2">{{ details.caption.category }}</div><div class="float-start period">{{ details.caption.period }}</div></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p class="h5 mb-4"><span class="caption">Стоимость проживания</span></p>
                    <p v-for="(item, index) in details.periods" :key="index"><span class="period">{{ item.period }}</span><span class="ps-2">{{ item.val }}</span></p>
                    <p class="total">{{ details.housing }}</p>
                    <p class="h5 mb-4"><span class="caption">Стоимость питания</span></p>
                    <p>Взрослые от 13 лет:</p>
                    <p v-for="(meal, index) in details.adultMeals" :key="index"><span class="period">{{ meal.period }}</span><span class="ps-2">{{ meal.val }}</span></p>
                    <p v-if="this.children">Дети до 13 лет:</p>
                    <p v-for="(meal, index) in details.childMeals" :key="index"><span class="period">{{ meal.period }}</span><span class="ps-2">{{ meal.val }}</span></p>
                    <p v-if="this.infants">Дети до 3 лет: бесплатно</p>
                    <p class="total">{{ details.meal }}</p>
                    <p class="total">{{ details.total }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-calc" data-bs-dismiss="modal">ЗАКРЫТЬ</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { Modal } from 'bootstrap'
export default {
    name: 'Host',
    data() {
        return {
            date: null,                         //даты для компонента Datepicker
            minDate: new Date(),                //мин. дата для компонента Datepicker
            maxDate: new Date("2025/01/08"),    //макс. дата для компонента Datepicker
            periods: null,                      //цены по периодам
            categories: null,                   //категории номеров
            foodPeriods: null,                  //стоимость питания по периодам
            adultPrices: null,                  //уникальные цены за питание для взрослых с кол-вом дней за выбранный период
            childPrices: null,                  //уникальные цены за питание для детей с кол-вом дней за выбранный период
            totalFoodCost: 0,                   //итоговая стоимость питания за период (взрослые + дети)
            showResult: false,                  //показать результат
            adults: 0,                          //кол-во взрослых
            children: 0,                        //кол-во детей от 4 до 12 лет
            infants: 0,                         //кол-во детей до 3 лет
            result: null,                       //результат расчета
            dates: [],                          //список дат в выбранном периоде
            dev: false,                          //флаг для разработки, перед билдом выставить false
            infoModal: null,
            details: {
                caption: { category: '', period: '' },
                periods: [{ period: '', val: '' }],
                housing: '',
                adultMeals: [{ period: '', val: '' }],
                childMeals: [{ period: '', val: '' }],
                meal: '',
                total: ''
            }
        }
    },
    mounted() {
        this.getPeriods(),
        this.getCategories(),
        this.getFoodPeriods(),
        this.infoModal = new Modal(this.$refs.infoModal)
    },
    computed: {
        showButtonCalc() {
            if (this.date && this.adults) {
                return true
            } else {
                return false
            }
        },
        graphqlUrl() {
            if (this.dev) {
                return "http://localhost:8000/graphql"
            } else {
                return "/graphql"
            }
        }
    },
    watch: {
        date(val) {
            if (!val) {
                this.showResult = false;
            }
        }        
    },
    methods: {
        async getPeriods() {
            try {
                const result = await axios({
                    method: 'POST',
                    url: this.graphqlUrl,
                    data: {
                        query: `
                            {
                                allPeriods {
                                    start
                                    end
                                    price
                                    category {
                                        id
                                        name
                                    }
                                }
                            }
                        `
                    }
                });
                this.periods = result.data.data.allPeriods
            } catch(error) {
                console.error(error)
            }
        },
        async getCategories() {
            try {
                const result = await axios({
                    method: 'POST',
                    url: this.graphqlUrl,
                    data: {
                        query: `
                            {
                                categories {
                                    id
                                    code
                                    name
                                    beds
                                }
                            }
                        `
                    }
                });
                this.categories = result.data.data.categories
            } catch(error) {
                console.error(error)
            }
        },
        async getFoodPeriods() {
            try {
                const result = await axios({
                    method: 'POST',
                    url: this.graphqlUrl,
                    data: {
                        query: `
                        {
                            foodPeriods {
                                start
                                end
                                adultPrice
                                childPrice
                            }
                        }
                    `
                    }
                });
                this.foodPeriods = result.data.data.foodPeriods
            } catch(error) {
                console.error(error)
            }
        },
        num(val) {
            if (val) {
                return val
            } else {
                return 0
            }
        },
        numRub(val) {
            return new Intl.NumberFormat('ru-RU').format(val)
        },
        setDates() {
            const start = new Date(this.date[0]);
            const end = new Date(this.date[1]);
            this.dates = [];

            for (let i = start; i < end; i.setDate(i.getDate() + 1)) {
                this.dates.push(i.toISOString().slice(0,10))    //дата в формате строки yyyy-mm-dd
            }
        },
        calcCategory(categoryId) {         
            const periods = this.periods;
            const dates = this.dates;
            let total = 0;
            let prices = [];
             
            dates.forEach(date => {
                for (let period of periods) {
                    if (period.start <= date && period.end >= date && period.category.id == categoryId) {
                        total = total + period.price;
                    
                        prices.push(period.price);
                    }
                }
            })               

            const countedPrices = [];
            let price = 0;
            let index = 0;
            let count = 1;

            for (let i = 0; i < prices.length; i++) {
                if (i === 0) {
                    price = prices[i];
                }
                if (prices[i] === price) {
                    countedPrices[index] = [prices[i], count];
                    count ++;
                } else {
                    price = prices[i];
                    index++;
                    count = 1;
                    countedPrices[index] = [prices[i], count];
                    count++;
                }
            }

            return {
                cost: total,
                prices: countedPrices
            };
        },
        calcMeal() {
            const periods = this.foodPeriods;
            const dates = this.dates;
            let adultTotal = 0;
            let childTotal = 0;
            let adultPrices = [];
            let childPrices = [];

            dates.forEach(date => {
                for (let period of periods) {
                    if (period.start <= date && period.end >= date) {
                        adultTotal = adultTotal + period.adultPrice;
                        childTotal = childTotal + period.childPrice;

                        adultPrices.push(period.adultPrice);
                        childPrices.push(period.childPrice);
                    }
                }
            })

            const total = this.adults * adultTotal + this.num(this.children) * childTotal;

            const countedAdultPrices = [];
            const countedChildPrices = [];
            let price = 0;
            let index = 0;
            let count = 1;

            for (let i = 0; i < adultPrices.length; i++) {
                if (i === 0) {
                    price = adultPrices[i];
                }
                if (adultPrices[i] === price) {
                    countedAdultPrices[index] = [adultPrices[i], count];
                    countedChildPrices[index] = [childPrices[i], count];
                    count ++;
                } else {
                    price = adultPrices[i];
                    index++;
                    count = 1;
                    countedAdultPrices[index] = [adultPrices[i], count];
                    countedChildPrices[index] = [childPrices[i], count];
                    count++;
                }
            }

            this.adultPrices = countedAdultPrices; 
            this.childPrices = countedChildPrices;
            this.totalFoodCost = total;
        },
        calcAllCategories() {
            this.showResult = false;
            const numberOfPeople = this.adults + this.num(this.children) + this.num(this.infants);
            this.setDates();
            this.calcMeal();
            let categories = [];
            
            this.categories.forEach((item) => {
                if (item.beds >= numberOfPeople) {
                    categories.push(item)
                }
            });

            categories.forEach((item, index) => {
                const calcCategory = this.calcCategory(item.id);
                categories[index].cost = calcCategory.cost + this.totalFoodCost;
                categories[index].prices = calcCategory.prices;
            });

            categories.sort((a, b) => {
                return a.cost - b.cost;
            })

            this.result = categories;
            this.showResult = true;
        },
        format() {
            const date = this.date;

            const day = date[0].getDate().toString().padStart(2, "0");
            const month = (date[0].getMonth() + 1).toString().padStart(2, "0");
            const year = date[0].getFullYear();

            const day1 = date[1].getDate().toString().padStart(2, "0");
            const month1 = (date[1].getMonth() + 1).toString().padStart(2, "0");
            const year1 = date[1].getFullYear();

            return `${day}.${month}.${year} - ${day1}.${month1}.${year1}`;
        },
        getNoun(number, one, two, five) {
            let n = Math.abs(number);
            
            n %= 100;
            if (n >= 5 && n <= 20) {
                return five;
            }
            
            n %= 10;
            if (n === 1) {
                return one;
            }
            if (n >= 2 && n <= 4) {
                return two;
            }

            return five;
        },
        dateLabel() {
            if (this.date) {
                const num = Math.floor((this.date[1] - this.date[0]) / 86400000);
                const text = this.getNoun(num, "ночь", "ночи", "ночей");
                return `Даты заезда (${num} ${text})`
            } else {
                return "Даты заезда"
            }
        },
        logoPath() {
            if (this.dev){
                return "logo.svg"        
            } else {
                return "/static/img/logo.svg"
            }
        },
        inx(arr, k) {
            let sum = 0;
            for (let i = k; i >= 0; i--) {
                sum = sum + arr[i][1];
            }
            return sum;
        },
        lDate(date) {
            return new Date(date).toLocaleDateString("ru-RU")
        },
        showModal(index) {
            const category = this.result[index].name;
            const start = this.lDate(this.date[0]);
            const end = this.lDate(this.date[1]);
            this.details.caption = {category: category, period: `${start}-${end}`};

            const dates = this.dates;
            const prices = this.result[index].prices;
            const adults = this.adults;
            const children = this.children;
            const adultPrices = this.adultPrices;
            const childPrices = this.childPrices;

            this.details.periods = [];
            this.details.adultMeals = [];
            this.details.childMeals = [];         
            let periods = [];
            let foodPeriods = [];
            

            for (let i = 0; i < prices.length; i++) {
                if (i === 0 && prices.length === 1) {
                    periods.push(`${start} - ${end}`)
                } else if (i === 0 && prices.length > 1) {
                    periods.push(`${start} - ${this.lDate(dates[prices[i][1] - 1])}`)
                } else if (i > 0 && i < prices.length - 1) {
                    periods.push(`${this.lDate(dates[this.inx(prices, i - 1)])} - ${this.lDate(dates[this.inx(prices, i) - 1])}`)
                } else if (i === prices.length - 1) {
                    periods.push(`${this.lDate(dates[this.inx(prices, i - 1)])} - ${end}`)
                }
            }

            let housing = 0;

            prices.forEach((price, index) => {
                housing += price[0] * price[1];
                const nights = this.getNoun(price[1],  "ночь", "ночи", "ночей")
                this.details.periods.push({ 
                    period: periods[index], 
                    val: `${price[1]} ${nights} x ${price[0]} руб. = ${price[0]*price[1]} руб.` 
                });
            })

            this.details.housing = `Итого за проживание: ${this.numRub(housing)} руб.`


            for (let i = 0; i < adultPrices.length; i++) {
                if (i === 0 && adultPrices.length === 1) {
                    foodPeriods.push(`${start} - ${end}`)
                } else if (i === 0 && adultPrices.length > 1) {
                    foodPeriods.push(`${start} - ${this.lDate(dates[adultPrices[i][1] - 1])}`)
                } else if (i > 0 && i < adultPrices.length - 1) {
                    foodPeriods.push(`${this.lDate(dates[this.inx(adultPrices, i - 1)])} - ${this.lDate(dates[this.inx(adultPrices, i) - 1])}`)
                } else if (i === adultPrices.length - 1) {
                    foodPeriods.push(`${this.lDate(dates[this.inx(adultPrices, i - 1)])} - ${end}`)
                }
            }

            let days;
            adultPrices.forEach((price, index) => {
                days = this.getNoun(price[1],  "день", "дня", "дней")
                this.details.adultMeals.push({ 
                    period: foodPeriods[index], 
                    val: `${adults} x ${price[1]} ${days} x ${price[0]} руб. = ${adults*price[0]*price[1]} руб.` 
                });
            })
            
            if (children) {
                childPrices.forEach((price, index) => {
                    days = this.getNoun(price[1],  "день", "дня", "дней")
                    this.details.childMeals.push({ 
                        period: foodPeriods[index], 
                        val: `${children} x ${price[1]} ${days} x ${price[0]} руб. = ${children*price[0]*price[1]} руб.` 
                    });
                })
            }

            this.details.meal = `Итого за питание: ${this.numRub(this.totalFoodCost)} руб.`

            this.details.total = `ИТОГО БРОНЬ: ${this.numRub(this.result[index].cost)} руб.`

            this.infoModal.show()
        },
    }
}
</script>
    
<style>
    body, .modal-content {
        background-color: #E2F4F3!important;
    }
    .main, .modal {
        font-family: Gilroy;
        font-weight: 600;
        letter-spacing: .17px;
        color: #5D534E;
    }
    .main .title {
        font-weight: 800;
    }
    .form-control {
        font-weight: 600!important;
        color: #5D534E!important;
        border-color: #00cfc7!important;
    }
    .form-control:focus {
        border-color: #00cfc7!important;
        box-shadow: 0 0 0 0.25rem rgb(0 207 199 / 25%)!important;
    }
    .btn-calc {
        font-weight: 600!important;
        letter-spacing: 1.21px;
        color: #ffffff!important;
        background-color: #36B5B0!important;
        border-color: #36B5B0!important;
        width: 100%;
    }
    .btn-calc:hover {
        background-color: #00A6A0!important;
        border-color: #00A6A0!important;
    }
    .btn-calc:focus {
        background-color: #00A6A0!important;
        border-color: #009999!important;
        box-shadow: 0 0 0 0.25rem rgb(0 207 199 / 50%)!important;
    }
    .dp__theme_light {
        --dp-icon-color: #00A6A0!important;
        --dp-hover-icon-color: #009999!important;
        --dp-primary-color: #00A6A0!important;
        --dp-text-color: #5D534E!important;
        --dp-hover-color: rgba(6,198,191,.16)!important;
        --dp-border-color-hover: #ced4da!important;
    }
    .dp__input {
        font-family: Gilroy!important;
        font-weight: 600!important;
        border-color: #00cfc7!important;
    }
    .dp__input_focus {
        border-color: #00cfc7!important;
        box-shadow: 0 0 0 0.25rem rgb(0 207 199 / 25%)!important;
    }
    .dp__month_year_row {
        font-weight: 700;
    }
    .dp__calendar {
        font-weight: 600;
    }
    .dp__instance_calendar {
        margin: 5px;
    }
    .dp__calendar_next {
        margin-left: 0!important;
    }
    .weekend {
        color: #fc6745;
    }
    .dp__inner_nav {
        border-radius: 4px!important;
        height: 35px!important;
        width: 35px!important;
    }
    .dp__inner_nav svg {
        height: 25px!important;
        width: 25px!important;
    }
    .dp__menu {
            box-shadow: 1px 2px 5px #888;
    }
    .info {
        cursor: pointer;
        border-bottom: #000 dotted 1px;
    }
    th.right, td.right {
        text-align: right;
    }
    .bg-calc {
        background-color: rgba(6,198,191,.16)!important;
    }
    .caption {
        /* color: #EA904F; */
        display: block;
        text-align: center;
        margin: 0px 2px;
        background: rgba(0, 0, 0, 0.02);
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        padding: 1px 4px;
        box-sizing: border-box;
        font-weight: 600;
    }
    p.total {
        font-weight: 700;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        /* margin-top: 0.5rem!important; */
        padding-top: 1rem;
    }
    /* code {
        margin: 0px 2px;
        background: rgba(0, 0, 0, 0.02);
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        padding: 1px 4px;
        box-sizing: border-box;
        font-weight: 500;
    } */
    .period {
        margin: 0px 2px;
        /* background: rgba(0, 0, 0, 0.02); */
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        padding: 1px 4px;
        box-sizing: border-box;
        font-weight: 500;
    }
    @media (min-width: 480px) {
        .navbar-brand img {
            max-width: 100%;
        }
    }
    @media (max-width: 767px) {
        .navbar-brand img {
            max-width: 83%;
        }
    }
    @font-face{
        font-family:Gilroy;
        font-style:normal;
        font-weight:600;
        src:url('~@/assets/fonts/Gilroy-SemiBold.woff') format('woff')
    }
    @font-face{
        font-family:Gilroy;
        font-style:normal;
        font-weight:700;
        src:url('~@/assets/fonts/Gilroy-Bold.woff') format('woff')
    }
    @font-face{
        font-family:Gilroy;
        font-style:normal;
        font-weight:800;
        src:url('~@/assets/fonts/Gilroy-ExtraBold.woff') format('woff')
    }
    @font-face{
        font-family:Gilroy;
        font-style:normal;
        font-weight:900;
        src:url('~@/assets/fonts/Gilroy-Heavy.woff') format('woff')
    }
    @font-face{
        font-family:Gilroy;
        font-style:normal;
        font-weight:400;
        src:url('~@/assets/fonts/Gilroy-Regular.woff') format('woff')
    }
    @font-face{
        font-family:Gilroy;
        font-style:normal;
        font-weight:500;
        src:url('~@/assets/fonts/Gilroy-Medium.woff') format('woff')
    }
    @font-face{
        font-family:IskraCYR-Bold;
        font-style:normal;
        font-weight:700;
        src:url('~@/assets/fonts/IskraCYR-Bold.woff') format('woff')
    }
</style>